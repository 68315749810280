













import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/VSelect.vue";

@Component({ components: { VSelect } })
export default class BannerGroupKindSelect extends Vue {
  @Prop()
  selected!: string | null;

  @Prop({ default: 'Tipologia' })
  label?: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: false })
  preselect: boolean;

  options: VSelectOption[] = [
    { id: 'carousel', text: 'Carousel' },
    { id: 'list', text: 'Lista' },
  ];

  private value: string | null = this.selected || null;

  @Watch('selected')
  onSelectedChanged(selected: string) {
    this.value = selected;
    this.onItemsReady();
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected.toString())?.id;
    }
  }
}
